import React from 'react'
import styles from './figcap.module.scss'
import PropTypes from 'prop-types'

const FigCap = ({
	src,
	alt,
    noCircle=false,
	noBorder=false,
	borderColor,
	borderWidth,
	imageWidth,
	children,
	className,
	...etc
}) => {
	return (
		<figure className={`${styles.fig} ${className}`} {...etc}>
			{noBorder ? (
				<img
					className={`${styles.noBorder} ${noCircle? styles.noCircle : ''}`}
					style={{ width: imageWidth }}
					src={src}
					alt={alt}
				></img>
			) : (
				<div
					className={styles.profileContainer}
					style={{
						backgroundColor: borderColor,
						padding: borderWidth,
					}}
				>
					<img
						className={styles.profile}
						style={{ width: imageWidth }}
						src={src}
						alt={alt}
					></img>
				</div>
			)}
			<figcaption className={styles.caption}>
				{children}
			</figcaption>
		</figure>
	)
}

FigCap.propTypes = {
    noCircle: PropTypes.bool,
	noBorder: PropTypes.bool,
	src: PropTypes.string,
	borderColor: PropTypes.string,
	borderWidth: PropTypes.string,
	imageWidth: PropTypes.string,
}
FigCap.defaultProps = {
    noBorder: false,
    noCircle: false,
	src: '',
	borderColor: '#aaa',
	borderWidth: '3px',
	imageWidth: '100%',
}

export default FigCap
