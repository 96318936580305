import React, { useEffect, useState, HTMLAttributes } from 'react'
import fetchJsonp from 'fetch-jsonp'
import Stats from './Stats'
interface ILangJson {
    name: string;
    percent: number;
}
interface ITimeJson {
    grand_total: {
        total_seconds: number;
    };
}
interface ILangData {
    name: string;
    seconds: number;
    minutes: number;
    hours: number;
}
interface ICodeStats extends HTMLAttributes<HTMLElement> {
    title: string;
    count?: number;
    filter?: string[];
    digits?: number;
}
export default function CodeStats({
    title,
    count=5,
    filter=['Research', 'Text', 'Other'],
    digits=2,
    ...etc
}: ICodeStats) {
    const [langStats, setLangStats] = useState<ILangData[]>([])

	const wakatimeTime = 'https://wakatime.com/share/@MrGVSV/af45b449-7454-48df-bcad-37987d0a69fa.json'
	const wakatimeLang = 'https://wakatime.com/share/@MrGVSV/11a57571-f2bc-471f-b7e7-2a9e23d5352e.json'

	useEffect(() => {
        fetchData();
	}, [])

	async function fetchData() {
		let timesPromise = fetchJsonp(wakatimeTime)
			.then(response => response.json())
			.catch(err => console.log(err))
		let langsPromise = fetchJsonp(wakatimeLang)
			.then(response => response.json())
			.catch(err => console.log(err))
		let all = Promise.all([timesPromise, langsPromise])
		all.then((jsons) => {
			if(typeof jsons[0] === 'undefined' || typeof jsons[1] === 'undefined') {
				return;
			}
			let { data: times } = jsons[0] as {data: ITimeJson[]}
			let { data: langs } = jsons[1] as {data: ILangJson[]}
			
			// === Get Total Time === //
			let totalSeconds = 0
			times.forEach(time => {
				totalSeconds += time.grand_total.total_seconds;
			})

			// === Get Language Times === //
            let langData: ILangData[] = []
            let digitMult = Math.pow(10, digits);
            const setDigit = (num: number) => Math.round(num * digitMult) / digitMult;
			langs.forEach(lang => {
				if(!filter.includes(lang.name)){
                    let seconds = lang.percent / 100 * totalSeconds;
                    let minutes = seconds / 60;
                    let hours = minutes / 60;
					let language: ILangData = {
						name: lang.name,
						seconds: setDigit(seconds),
                        minutes: setDigit(minutes),
                        hours: setDigit(hours)
					}
					langData.push(language)
				}
            })

			setLangStats(langData.slice(0, count));
		})
    }
    
    let legend = (data: number, label: string) => `${label}: ${data} hours.`

	return (
		<Stats
            title={title}
			data={langStats.map(stat => stat.hours)}
			labels={langStats.map(stat => stat.name)}
			legendFormat={legend}
			{...etc}
        />
	)
}
