import React, { useState, HTMLAttributes } from 'react'
import { Pie } from 'react-chartjs-2'
import nextId from 'react-id-generator'

interface IStatistics extends HTMLAttributes<HTMLElement> {
	title: string
	labels: string[]
	data: number[]
	legendFormat(data: number, label: string): string;
    fallback?: string
	legendPosition?: LegendPosition
	hideLegend?: boolean
	textColor?: string
	textSize?: number
    boxWidth?: number
}
export default function Stats({
	title,
	data,
	labels,
    legendFormat,
    fallback='',
	legendPosition = LegendPosition.RIGHT,
	hideLegend = false,
	textColor = 'black',
	textSize = 10,
	boxWidth = 20,
	className,
	style,
	...etc
}: IStatistics) {
	const [legendPos, setLegendPos] = useState<LegendPosition>(
		legendPosition
    )

	let chartDataSet = [
		{
			data: data,
			backgroundColor: chartColors,
		},
	]

	const chartProps = {
		data: {
			labels: labels,
			datasets: chartDataSet,
		},
		options: {
			title: {
				display: title.length > 0,
				text: title,
			},
			legend: {
				display: !hideLegend,
				position: legendPos,
				align: 'center',
				labels: {
					fontColor: textColor,
					fontSize: textSize,
					boxWidth: boxWidth,
				},
			},
			responsive: true,
			maintainAspectRatio: false,
			onResize: (_chart: any, newSize: { width: number }) => {
				if (newSize.width < 200) {
					setLegendPos(LegendPosition.BOTTOM)
				} else if (legendPos !== LegendPosition.RIGHT) {
					setLegendPos(LegendPosition.RIGHT)
				}
			},
		},
		width: 350,
		height: 200,
	}

    const tableId = nextId('chart-accessibility--')
	
	if (chartDataSet[0].data.length > 0) {
		return (
			<div
				className={className}
				style={{ width: '100%', ...style }}
				role="img"
				aria-label={title}
				aria-describedby={tableId}
			>
				<Pie {...chartProps} />
				<ol style={{ display: 'none' }} id={tableId}>
					{createAccessibilityList(
                        data,
                        labels,
                        legendFormat
                    )}
				</ol>
			</div>
		)
	} else {
		return <p>{fallback}</p>
	}
}

function createAccessibilityList(
	data: number[],
	labels: string[],
	format: (data: number, label: string) => string
) {
	let list = []
	for (let i = 0; i < data.length && i < labels.length; i++) {
		list.push(
			<li key={`${labels[i]}-${data[i]}-${i}`}>
				{format(data[i], labels[i])}
			</li>
		)
	}
	return list
}

export enum LegendPosition {
	RIGHT = 'right',
	BOTTOM = 'bottom',
}


const chartColors = [
	'#332288',
	'#117733',
	'#44AA99',
	'#88CCEE',
	'#DDCC77',
	'#CC6677',
	'#AA4499',
	'#882255',
	'#777',
	'#332288',
	'#117733',
	'#44AA99',
	'#88CCEE',
	'#DDCC77',
	'#CC6677',
	'#AA4499',
	'#882255',
]
