import React from 'react'
import PropTypes, { string } from 'prop-types'
import SEO from 'Components/seo'
import Navbar from 'Components/navbar'
import 'SCSS/about.scss'
import FigCap from 'Templates/figcap'
import profile from 'Assets/images/gino.jpg'
import styles from 'SCSS/about.scss'
import CodeStats from './components/CodeStats'
import { graphql } from 'gatsby'
import { FigCapFlex } from './components/technology'

const AboutPage = ({ data, transitionStatus, exit, enter, mount }) => {
    
	return (
		<div className="page">
			<SEO title="About" url='/about' description='About page for Gino Valente' />
			<Navbar from="/about" />
			<div
				className="page-main"
				style={{ overflow: 'hidden' }}
			>
				<div className="about-container">
					<div className="about-phone-container">
						<div className="about-phone">
							<FrontCamera />
							<div className="about-phone-content">
								<FigCap
									src={
										profile
									}
									imageWidth={
										'65%'
									}
									className={'content-child'}
								>
									<h3 className="profile-name">
										Gino Valente
									</h3>
									<p className="profile-username">
										@TheGinoValente
									</p>
								</FigCap>
								<main
									className="phone-bio"
								>
									<SectionHeading section="Bio" />
									<Bio className='profile-section' />
								</main>
								<SectionHeading section="Education" />
								<Education />
								<SectionHeading section="Technologies" />
                                <FigCapFlex jsonEdges={data.allTechnologiesJson.edges} iconEdges={data.allFile.edges} lines={5} className={'content-child'}/>
                                <SectionHeading section="Main Languages" />
                                <FigCapFlex jsonEdges={data.allLanguagesJson.edges} iconEdges={data.allFile.edges} lines={5} className={'content-child'}/>
								<SectionHeading section="Statistics" />
								<CodeStats className='profile-section content-child' title='Top Languages in Hours (past 30 days)' count={5} textColor={styles.textColor} />
								{/* <GameStats className='profile-section content-child' title='Games Most Played (by hours)' count={5} textColor={styles.textColor} /> */}
								{/* <SectionHeading section="Favorite..." />
								<TabTable title="Games" list={['Smash Bros.', 'BFBB', 'Dwarf Fortress', 'Terraria', 'Factorio']} />
								<TabTable title="Movies" list={['Groundhog Day', 'Shaun of the Dead', 'Airplane!', '2001: A Space Odyssey']} /> */}
								<p className={'credits'}>All icons provided by <a href={'https://icons8.com/'} className={'credits content-child'}>icons8.com</a></p>
							</div>
							<PhoneHomeBtn />
						</div>
					</div>
					<main
						className="about-main"
						style={{ overflow: 'scroll' }}
					>
                        <h6 className={'about-main-content align-right about-subtitle'}>My name is</h6>
                        <h1 className={'about-main-content align-right about-title'}>Gino Valente</h1>
                        <Bio className={'about-main-content'} />
                        <hr className={'about-main-content'} />
					</main>
				</div>
			</div>
		</div>
    )
}

export const iconQuery = graphql`
query iconQuery {
    allTechnologiesJson {
        edges {
          node {
            id
            name
            icon
            alt
          }
        }
        totalCount
      }
      allLanguagesJson {
        edges {
          node {
            id
            name
            icon
            alt
          }
        }
        totalCount
      }
      allFile(filter: {absolutePath: {regex: "/icons/"}}) {
        edges {
          node {
            base
            publicURL
          }
        }
      }
  }
`

const SectionHeading = ({ section, ...etc }) => (
	<h5 {...etc} className="profile-section-heading content-child">
		{section}
	</h5>
)

const TabTable = ({ title, list=[], ...etx }) => (
	<div className='profile-section profile-table'>
			<div className='profile-table-header'>{title}</div>
			<div className='profile-table-content'>
			{list.map((item, index) => (
			<p key={`${index}-${item}`}>{item}</p>
		))}
			</div>
	</div>
)
TabTable.propTypes = {
	list: PropTypes.arrayOf(string)
}

const Education = props => {
	return (
		<div
			className='profile-section profile-section-flex content-child'
		>
			<p className="profile-section-item">
				B.A. in Cinema & Media Arts
				<br />
				Minor in Computer Science
			</p>
			<p className="profile-section-item">
				Biola University
				<br />
				Class of 2020
			</p>
		</div>
	)
}

const Bio = props => {
	return (
		<p {...props}>
			I am a game developer and designer with an emphasis on <span className='highlight'>creativity</span> and <span className='highlight'>collaboration</span>. Coming from a background in film, I understand and appreciate the importance of both of those things.
            <br /><br />
            I grew up playing Minecraft, through which I learned about concepts like binary, logic, and basic programming through redstone. Since then, I have taught myself a wide variety of programming languages and technologies. I was part of the robotics club in high school and am now working alongside others to create apps and make games.
            <br /><br />
            My dream in life is to <span className='highlight'>inspire</span> someone. This is why games are so dear to me. I want to inspire someone to go create and do what they love, just as Minecraft did for me nearly 7 years ago.
		</p>
	)
}

const FrontCamera = props => {
	return (
		<svg className="about-phone-camera" viewBox="0 0 92 10" xmlns="http://www.w3.org/2000/svg">
			<path d="M0 5.5H72.5" />
			<circle cx="87" cy="5" r="4.5" />
		</svg>
	)
}

const PhoneHomeBtn = props => {
	return (
		<svg className='about-phone-home' viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
			<circle cx="20" cy="20" r="19.5" />
		</svg>

	)
}

export default AboutPage
