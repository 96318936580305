import React from 'react'
import FigCap from 'Templates/figcap'
import styles from './technology.module.scss'

export default function Tech({ name, src, alt, imageWidth='75%', captionClass,  ...etc }) {
	return (
			<FigCap
				noCircle
				noBorder
                src={src}
                alt={alt}
				imageWidth={imageWidth}
                {...etc}
			>
				<p className={captionClass}>{name}</p>
			</FigCap>
	)
}

export const FigCapFlex = ({ noNames, jsonEdges=[], iconEdges=[], lines=4, itemWidth='', className, ...etc }) => {
    return (
        <div className={`${styles.techFlex} ${className}`} {...etc}>
            {jsonEdges.map(edge => {
                
                // === Get Src of Icon === //
                let srcEdge = iconEdges.find(e => e.node.base === edge.node.icon);
                let src = srcEdge ? srcEdge.node.publicURL : '';
                // === Get Icon Alt === //
                let alt = edge.node.alt ? edge.node.alt : edge.node.name   
                // === Get Column Width === //
                let colWidth = itemWidth.length > 0 ? itemWidth : `${100 / lines}%`

                return (
                    <Tech key={edge.node.id} name={!noNames ? edge.node.name : ''} src={src} alt={alt} imageWidth={'75%'} style={{width: colWidth}} className={styles.techFig} captionClass={styles.techName} />
            )})}
        </div>
    )
}